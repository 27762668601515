import 'nodelist-foreach'

import Store from '@store'
import Layers from '@layers'

import LeafletMap from 'abstractions/LeafletMap'
import DomComponent from 'abstractions/DomComponent'
import MapAnchor from 'components/MapAnchor'
import MapScale from 'components/MapScale'

export default class Map extends DomComponent {
  didInit () {
    this.LeafletMapOptions = {
      bbox: Store.bbox.current,
      zoomRange: Store.zoomRange.current,
      onload: () => this.addClass('is-ready'),
      onmove: function (center) { Store.center.set(center) },
      onzoom: function () { Store.zoom.set(this.zoom) }
    }

    this.bindFuncs(['setFullscreen'])
  }

  didMount () {
    this.refs.container = this.refs.base.querySelector('.map__container')
    this.refs.layers = document.querySelector('.map-layers__entries')
    this.refs.scale = this.registerComponent(MapScale)
    this.refs.controls = {
      center: this.refs.base.querySelector('.map__control--center'),
      toggle: this.refs.base.querySelector('.map__control--expand')
    }

    this.leafletMap = new LeafletMap(this.refs.container, this.LeafletMapOptions)

    // Add all stored layers to the map instance. Note that layer rendering is
    // done lazyly, meaning that only layers are rendered only when needed (when
    // map updates and when the layer is visible)
    for (let layer of Store.layers.current) {
      layer.toMap(this.leafletMap, { update: false })
    }
    this.leafletMap.updateLayers()

    // Add all stored layers to the layers list, accordingly to the stored order
    for (let layerTitle of Store.layersEntries.current) {
      const layer = Layers.get(layerTitle)
      if (layer) layer.toDOM(this.refs.layers)
    }

    // Center control
    if (this.refs.controls.center) {
      this.refs.controls.center.addEventListener('focus', this.refs.controls.center.select)
      this.refs.controls.center.addEventListener('click', this.refs.controls.center.select)
      this.refs.controls.center.addEventListener('change', () => {
        const value = this.refs.controls.center.value
        const [lat, lng] = value && value.split(';').map(v => +v)
        if (!isNaN(lat) && !isNaN(lng)) Store.center.set({ lat, lng })
      })
    }

    // Toggle fullwidth expand
    if (this.refs.controls.toggle) {
      this.refs.controls.toggle.addEventListener('click', () => {
        Store.fullscreen.set(!Store.fullscreen.current)
      })
    }

    // MapScale
    this.refs.scale.mount(this.refs.base)

    this.reset()
    this.bindStore()
  }

  bindStore () {
    Store.fullscreen.listen(this.setFullscreen)
    Store.layers.listen(zoom => this.leafletMap.updateLayers())
    Store.zoom.listen(zoom => this.leafletMap.setZoom(zoom))
    Store.center.listen(center => {
      if (!center) return
      if (!center.fromMoveEvent) this.leafletMap.setCenter(center)
      if (this.refs.controls.center) {
        this.refs.controls.center.value = `${center.lat};${center.lng}`
        this.refs.controls.center.size = this.refs.controls.center.value.length + 1
      }
    })
  }

  bindAnchors (container) {
    if (this.refs.anchors) {
      this.refs.anchors.forEach(anchor => anchor.destroy())
    }

    this.refs.anchors = []
    container.querySelectorAll('.map-anchor').forEach(element => {
      this.refs.anchors.push(new MapAnchor(element))
    })
  }

  reset ({ resetZoom = true } = {}) {
    this.leafletMap.reset({ resetZoom })
  }

  setFullscreen (fullscreen) {
    this[fullscreen ? 'addClass' : 'removeClass']('is-fullscreen')
    this.reset({ resetZoom: false })
  }
}
