import 'nodelist-foreach'
import lozad from 'lozad'

import DomComponent from 'abstractions/DomComponent'
import Slideshow from 'components/Slideshow'
import Photoswipe from 'controllers/photoSwipe'
import lastOf from 'utils/array-last'

const SLIDESHOW_MIN_LENGTH = 2

export default class Article extends DomComponent {
  didInit () {
    this.slideshows = []
  }

  didMount () {
    this.refs.figures = this.refs.base.querySelectorAll('figure')

    // Group figures by proximity in the DOM so that we can create galleries
    const groupedFigures = this.groupFigures(this.refs.figures)

    // Photoswipe and (if needed) slideshow
    for (let figures of groupedFigures) {
      let slideshow
      if (figures.length >= SLIDESHOW_MIN_LENGTH) {
        slideshow = new Slideshow(figures)
        slideshow.mount(this.refs.base, lastOf(figures))
        this.slideshows.push(slideshow)
      }

      Photoswipe.bindGallery(figures, {
        onSelect: index => slideshow && slideshow.select(index)
      })
    }

    // Lazyloading
    this.observer = lozad(this.refs.base.querySelectorAll('figure img'), {
      loaded: el => {
        const figure = el.parentNode
        if (!figure) return

        for (let slideshow of this.slideshows) {
          if (!slideshow.mounted) return
          if (slideshow.has(figure)) {
            slideshow.resize()
            break
          }
        }
      }
    })
    this.observer.observe()
  }

  groupFigures (figures, { excludedClassName = 'no-diapo' } = {}) {
    let buffer = []
    const chunks = []
    for (let figure of figures) {
      if (figure.classList && figure.classList.contains(excludedClassName)) {
        chunks.push([...buffer])
        chunks.push([figure])
        buffer.length = 0
        continue
      }

      buffer.push(figure)
      const next = figure.nextElementSibling
      const hasNext = next &&
        next.tagName === 'FIGURE' &&
        (next.classList && !next.classList.contains(excludedClassName))
      if (hasNext) continue

      chunks.push([...buffer])
      buffer.length = 0
    }

    return chunks
  }

  willUnmount () {
    this.observer = undefined
    this.slideshows.forEach(slideshow => slideshow.destroy())
    Photoswipe.destroy()
  }
}
