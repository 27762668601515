import 'nodelist-foreach'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import noop from 'utils/noop'

let photoswipe
const CONTAINER = document.querySelector('.pswp')
const OPTIONS = {
  bgOpacity: 1,
  history: false,
  allowPanToNext: false,
  barsSize: { top: 0, bottom: 'auto' },
  captionEl: true,
  fullscreenEl: true,
  zoomEl: false,
  shareEl: false,
  counterEl: true,
  showHideOpacity: true,
  getThumbBoundsFn: (index) => {
    const slide = photoswipe.items[index]
    if (!slide) return

    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
    const { left, top, width } = slide.element.getBoundingClientRect()
    return {
      x: left,
      y: top + pageYScroll,
      w: width
    }
  }
}

export function bindGallery (elements, {
  selector = 'img',
  onSelect = noop
} = {}) {
  if (!CONTAINER) return
  if (!elements || !elements.length) return

  const slides = []
  elements.forEach((element, index) => {
    if (selector) element = element.querySelector(selector)

    element.classList.add('has-photoswipe')
    slides.push({
      index,
      element,
      title: element.getAttribute('title'),
      src: element.getAttribute('data-zoom-src'),
      w: element.getAttribute('data-width'),
      h: element.getAttribute('data-height')
    })

    element.addEventListener('click', e => {
      photoswipe = new PhotoSwipe(CONTAINER, PhotoSwipeUIDefault, slides, Object.assign(OPTIONS, { index }))
      photoswipe.init()
      photoswipe.listen('afterChange', () => onSelect(photoswipe.getCurrentIndex()))
    })
  })
}

export function destroy () {
  try {
    photoswipe.destroy()
  } catch (e) {} finally {
    photoswipe = undefined
  }
}

export default { bindGallery, destroy }
