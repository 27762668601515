/**
 * layers
 *
 * This file controls the declaration of all layers used throughout this project
 * wether in Leaflet's MapPlayer abstraction (as a map layer) or in DOM (as a
 * map layer label)
 *
 * The layers are declared unsorted. A `use` function allows getting an array of
 * layers references from a sorted list of layer titles.
 *
 * SEE store.js
 */

/* global PACKAGE_VERSION */

import Layer from 'abstractions/Layer'
import ContoursLayer from 'abstractions/Layer/ContoursLayer'
import DebugLayer from 'abstractions/Layer/DebugLayer'
import MarkersLayer from 'abstractions/Layer/MarkersLayer'
import PointsLayer from 'abstractions/Layer/PointsLayer'
import TilesLayer from 'abstractions/Layer/TilesLayer'

const tilesURL = dir => dir + '/{z}/{x}-{y}.png?v=' + PACKAGE_VERSION

export const layers = !window.map_env ? [] : [
  new DebugLayer('Debug', {
    visible: false,
    icon: '/assets/icons/hexagon.svg'
  }),

  new TilesLayer('Territoire attractif', {
    visible: false,
    suffix: 'Programme d’actions — carte 1',
    icon: '/assets/icons/signs.svg',
    tiles: tilesURL('/assets/layers/territoire-attractif'),
    legend: [
      { label: 'Affirmer les côtes dans leur valeur nourricière', filename: 'CU1-4', fill: '#ffdc00' },
      { label: 'Tracé du GR5', filename: 'C20', shape: 'line', strokeWidth: 2, stroke: '#ff3c3c' }
    ],
    children: [
      new ContoursLayer('Affirmer les côtes dans leur valeur nourricière', {
        visible: true,
        geojsonUrl: window.map_env.geojson['cu1-4.geojson'],
        interactive: true,
        isolable: false,
        hiddenFromDOM: true,
        getLabel: feature => feature.properties['ID'],
        surface: { color: '#ffdc00', weight: 2 }
      }),
      new PointsLayer('Signaler les seuils visibles du GR5', {
        visible: true,
        interactive: false,
        zoomRange: [2, 7],
        icon: '/assets/icons/signs.svg',
        geojsonUrl: window.map_env.geojson['cu1-1.geojson'],
        symbol: '/assets/icons/markers/signs.svg',
        symbolSize: [20, 20]
      }),
      new PointsLayer('Signaler les points de départ d’itinéraires et stationnements possibles', {
        visible: true,
        interactive: false,
        zoomRange: [2, 7],
        icon: '/assets/icons/parking.svg',
        geojsonUrl: window.map_env.geojson['cu1-2.geojson'],
        symbol: '/assets/icons/markers/parking.svg',
        symbolSize: [20, 20]
      }),
      new PointsLayer('Localiser et créer des lieux de convivialité', {
        visible: true,
        interactive: false,
        zoomRange: [2, 7],
        icon: '/assets/icons/marker.svg',
        geojsonUrl: window.map_env.geojson['cu1-3.geojson'],
        symbol: '/assets/icons/marker.svg',
        symbolSize: [20, 20]
      })
    ]
  }),

  new TilesLayer('Paysages connus lisibles et vivants', {
    visible: false,
    suffix: 'Programme d’actions — carte 2',
    icon: '/assets/icons/mountain.svg',
    tiles: tilesURL('/assets/layers/paysages-connus'),
    legend: [
      { label: 'Rouvrir des chemins', filename: 'CU2-2', shape: 'line', stroke: '#c702f0', strokeWidth: 4 },
      { label: 'Identifier des parcours pour découvrir/comprendre la forêt', filename: 'CU2-2', shape: 'line', stroke: '#0b6732', strokeWidth: 4 }
    ],
    children: [
      new PointsLayer('Légende (cachée)', {
        visible: true,
        interactive: true,
        hiddenFromDOM: true,
        getLabel: feature => feature.properties['legende'],
        geojsonUrl: window.map_env.geojson['cu2-l.geojson'],
        symbol: 'char:i',
        getColor: feature => {
          switch (feature.properties.calque) {
            case 'identifier parcours decouverte foret': return '#0b6732'
            case 'reouverture-chemins': return '#c702f0'
            default: return 'black'
          }
        },
        symbolSize: [20, 20]
      }),
      new PointsLayer('Aménager les belvédères', {
        visible: true,
        interactive: true,
        zoomRange: [2, 7],
        icon: '/assets/icons/panorama.svg',
        geojsonUrl: window.map_env.geojson['cu2-5.geojson'],
        getLabel: feature => feature.properties['ID'],
        symbol: '/assets/icons/markers/panorama.svg',
        symbolSize: [20, 20]
      }),
      new PointsLayer('Aménager les sites d’immersion dans le paysage', {
        visible: true,
        interactive: true,
        zoomRange: [2, 7],
        geojsonUrl: window.map_env.geojson['cu2-1.geojson'],
        getLabel: feature => feature.properties['ID'],
        symbol: 'circle',
        symbolSize: [12, 12],
        symbolColor: '#0b6732'
      }),
      new PointsLayer('Faire connaître les côtes de nuit', {
        visible: true,
        interactive: false,
        zoomRange: [2, 7],
        icon: '/assets/icons/moon.svg',
        geojsonUrl: window.map_env.geojson['cu2-4.geojson'],
        symbol: '/assets/icons/moon.svg',
        symbolSize: [16, 16]
      })
    ]
  }),

  new TilesLayer('Transition énergétique', {
    visible: false,
    suffix: 'Programme d’actions — carte 3',
    icon: '/assets/icons/building.svg',
    tiles: tilesURL('/assets/layers/transition-energetique'),
    legend: [
      { label: 'Conforter les atouts existants', filename: 'CU3-1', shape: 'line', stroke: '#4accef', strokeWidth: 2 },
      { label: 'Projets et envies exprimés par les élu.es', filename: 'CU3-2', shape: 'line', stroke: '#129e9f', strokeWidth: 2 },
      { label: 'Routes dangereuses : absence de pistes cyclables et inconfort total des piétons', filename: 'CU3-3', shape: 'line', stroke: '#f91b1c', strokeWidth: 2 },
      { label: 'Itinéraires piétons existants possibles, à valoriser', filename: 'CU3-4', shape: 'line', stroke: '#097fab', strokeWidth: 2 },
      { label: 'Itinéraires secondaires piétons existants possibles, à valoriser', filename: 'CU3-4', shape: 'line', stroke: '#097fab', strokeWidth: 2, dash: [3, 3] }
    ],
    children: [
      new ContoursLayer('Itinéaires piétons existants possibles, à valoriser', {
        visible: true,
        geojsonUrl: window.map_env.geojson['cu3-9.geojson'],
        interactive: true,
        hiddenFromDOM: true,
        getLabel: feature => feature.properties['ID'],
        surface: { color: '#097fab', weight: 2 }
      }),
      new PointsLayer('Légende (cachée)', {
        visible: true,
        interactive: true,
        hiddenFromDOM: true,
        getLabel: feature => feature.properties['lgende'],
        geojsonUrl: window.map_env.geojson['cu3-l.geojson'],
        symbol: 'char:i',
        getColor: feature => {
          switch (feature.properties.calque) {
            case 'Mobilite-projet-envies-elus': return '#129e9f'
            case 'mobilite-atouts': return '#4accef'
            default: return 'black'
          }
        },
        symbolSize: [20, 20]
      }),
      new PointsLayer('Stationnements à revoir', {
        visible: true,
        interactive: true,
        getLabel: feature => feature.properties['ID'],
        geojsonUrl: window.map_env.geojson['cu3-5.geojson'],
        icon: '/assets/icons/car.svg',
        symbol: '/assets/icons/markers/car.svg',
        symbolSize: [20, 20]
      }),
      new PointsLayer('Aménager les carrefours dangereux pour piétons et cycles', {
        visible: true,
        interactive: false,
        geojsonUrl: window.map_env.geojson['cu3-7.geojson'],
        symbol: 'circle',
        symbolSize: [12, 12],
        symbolColor: '#b01b33'
      }),
      new PointsLayer('Points de départ itinéraires piétons à valoriser', {
        visible: true,
        interactive: true,
        getLabel: feature => feature.properties['ID'],
        geojsonUrl: window.map_env.geojson['cu3-8.geojson'],
        symbol: 'circle',
        symbolSize: [12, 12],
        symbolColor: '#097fab'
      }),
      new PointsLayer('Des espaces publics à adapter pour tous et toutes', {
        visible: true,
        interactive: false,
        geojsonUrl: window.map_env.geojson['cu3-10.geojson'],
        symbol: 'circle',
        symbolSize: [12, 12],
        symbolColor: '#ee81ec'
      }),
      new PointsLayer('Entrées de villes qui posent question', {
        visible: true,
        interactive: false,
        geojsonUrl: window.map_env.geojson['cu3-11.geojson'],
        symbol: 'circle',
        symbolSize: [12, 12],
        symbolColor: '#beb505'
      }),
      new PointsLayer('Extinction nocturne en place', {
        visible: true,
        interactive: true,
        getLabel: feature => feature.properties['ID'],
        geojsonUrl: window.map_env.geojson['cu3-12.geojson'],
        symbol: 'circle',
        symbolSize: [12, 12],
        symbolColor: '#060107'
      }),
      new PointsLayer('Extinction nocturne en cours', {
        visible: true,
        interactive: true,
        getLabel: feature => feature.properties['ID'],
        geojsonUrl: window.map_env.geojson['cu3-13.geojson'],
        symbol: 'circle',
        symbolSize: [12, 12],
        symbolColor: '#848889'
      })
    ]
  }),

  new Layer('Transition écologique', {
    visible: false,
    suffix: 'Programme d’actions — carte 4',
    icon: '/assets/icons/wheat.svg',
    legend: [
      { label: 'Prendre soin des milieux aquatiques', shape: 'square', stroke: '#117cfb', strokeWidth: 4 },
      { label: 'Veiller au maintien et au développement de la diversité des milieux', shape: 'square', stroke: '#318831', strokeWidth: 4 },
      { label: 'Zones de pâturage déjà en place', shape: 'square', stroke: '#ee8333', strokeWidth: 4 },
      { label: 'Itinéraires de transhumance', shape: 'line', stroke: '#ee8333', strokeWidth: 4, dash: [3, 3] },
      { label: 'Aller vers un territoire perméable et offrir des îlots de fraîcheur : cours d’école, places de mairie, stationnements petits et grands, nouveaux espaces publics…', shape: 'circle', fill: '#81ea6a' }
    ],
    children: [
      new ContoursLayer('Milieux aquatiques', {
        visible: true,
        hiddenFromDOM: true,
        geojsonUrl: window.map_env.geojson['cu4-1.geojson'],
        isolable: window.map_env.editor,
        interactive: true,
        getLabel: feature => feature.properties['ID'],
        surface: { color: '#117cfb', weight: 2 }
      }),
      new ContoursLayer('Diversité des milieux', {
        visible: true,
        hiddenFromDOM: true,
        geojsonUrl: window.map_env.geojson['cu4-2.geojson'],
        isolable: window.map_env.editor,
        interactive: true,
        getLabel: feature => feature.properties['ID'],
        surface: { color: '#318831', weight: 2 }
      }),
      new Layer('Transhumance', {
        visible: true,
        hiddenFromDOM: true,
        children: [
          new ContoursLayer('Zones de pâturage', {
            geojsonUrl: window.map_env.geojson['cu4-3.geojson'],
            isolable: window.map_env.editor,
            interactive: false,
            surface: { color: '#ee8333', weight: 2 }
          }),
          new TilesLayer('Itinéaire de transhumance', {
            tiles: tilesURL('/assets/layers/transition-ecologique')
          })
        ]
      }),
      new PointsLayer('Ilôts de fraicheur', {
        visible: true,
        hiddenFromDOM: true,
        geojsonUrl: window.map_env.geojson['cu4-5.geojson'],
        interactive: false,
        symbol: 'circle',
        symbolColor: '#81ea6a',
        symbolSize: [16, 16]
      })
    ]
  }),

  new ContoursLayer('Communes du Plan paysage Côtes de Moselle', {
    UID: '884c5aad19b76c5e2c02ae36c4fe744c59ab70ce',
    visible: false,
    icon: '/assets/icons/hexagon.svg',
    geojsonUrl: window.map_env.geojson['c02.geojson'],
    isolable: window.map_env.editor,
    interactive: true,
    getLabel: feature => feature.properties['NOM'],
    surface: { color: 'black', weight: 2 },
    overlay: { color: 'white', fillOpacity: 0.75 }
  }),

  new TilesLayer('Nuit', {
    solo: true,
    visible: false,
    actionable: window.map_env.editor || !window.ENV.production,
    icon: '/assets/icons/moon.svg',
    tiles: tilesURL('/assets/layers/nuit')
  }),

  new ContoursLayer('Les treize secteurs', {
    UID: '90884bd7b01c5aa59011a5630becbdd9258cdb1f',
    icon: '/assets/icons/map.svg',
    geojsonUrl: window.map_env.geojson['c48.geojson'],
    isolable: window.map_env.editor,
    interactive: true,
    visible: false,
    getLabel: feature => feature.properties['NOM'],
    surface: { color: '#ff3c3c', weight: 2 }
  }),

  new TilesLayer('Espaces agricoles', {
    visible: false,
    icon: '/assets/icons/wheat.svg',
    tiles: tilesURL('/assets/layers/espaces-agricoles'),
    legend: [
      { label: 'Pâturages', filename: 'C23', fill: '#a0c800' },
      { label: 'Vignes et vergers', filename: 'C24', fill: '#648200' },
      { label: 'Grandes cultures', filename: 'C25', fill: '#ffdc00' }
    ]
  }),

  new TilesLayer('Espaces de nature', {
    visible: false,
    icon: '/assets/icons/tree.svg',
    tiles: tilesURL('/assets/layers/espaces-de-nature'),
    legend: [
      { label: 'Boisements', filename: 'C21', fill: '#00a050' },
      { label: 'Milieux aquatiques et humides', filename: 'C52', fill: '#00b4a0' },
      { label: 'Parcelles qui s’enfrichent', filename: 'C30', fill: '#00461e' },
      { label: 'Espaces végétalisés urbains', filename: 'C26', fill: '#99b5a5' },
      { label: 'Pelouses calcicoles et prairies', filename: 'C22', fill: '#78dc3c' }
    ]
  }),

  new TilesLayer('Hydrographie', {
    visible: false,
    icon: '/assets/icons/drop.svg',
    tiles: tilesURL('/assets/layers/hydrographie'),
    zoomRange: [2, 7],
    legend: [
      { label: 'La Moselle, ses étangs et les gravières', filename: 'C03', fill: '#00d2ff' },
      { label: 'Les affluents: ruisseaux et rus', filename: 'C04', shape: 'line', stroke: '#00d2ff', strokeWidth: 4 }
    ],
    children: [
      new MarkersLayer('Sources & résurgences', {
        icon: '/assets/icons/drop.svg',
        zoomRange: [1, 7],
        markers: window.map_env.markers['Sources']
      })
    ]
  }),

  new Layer('Statuts de gestion, protection et de mise en valeur', {
    icon: '/assets/icons/map.svg',
    visible: false,
    children: [
      new ContoursLayer('Parc Naturel Régional de Lorraine', {
        icon: '/assets/icons/map.svg',
        geojsonUrl: window.map_env.geojson['c27.geojson'],
        isolable: window.map_env.editor,
        interactive: true,
        visible: false,
        surface: { color: 'rgb(0,40,120)', weight: 1 }
      }),
      new ContoursLayer('Espaces Natura 2000', {
        icon: '/assets/icons/map.svg',
        geojsonUrl: window.map_env.geojson['c54.geojson'],
        isolable: window.map_env.editor,
        interactive: true,
        visible: false,
        getLabel: () => 'Espace Natura 2000',
        surface: { color: 'rgb(0,40,120)', weight: 1 }
      }),
      new ContoursLayer('ZNIEFF', {
        icon: '/assets/icons/map.svg',
        geojsonUrl: window.map_env.geojson['c53.geojson'],
        isolable: window.map_env.editor,
        interactive: true,
        visible: false,
        surface: { color: 'rgb(0,40,120)', weight: 1 }
      }),
      new ContoursLayer('Sites classés, périmètres de protection', {
        icon: '/assets/icons/map.svg',
        geojsonUrl: window.map_env.geojson['c51.geojson'],
        isolable: window.map_env.editor,
        interactive: true,
        visible: false,
        getLabel: feature => feature.properties['lib_ass'],
        surface: { color: 'rgb(0,40,120)', weight: 1 }
      }),
      new ContoursLayer('Forêts communales', {
        icon: '/assets/icons/map.svg',
        geojsonUrl: window.map_env.geojson['c38.geojson'],
        isolable: window.map_env.editor,
        interactive: true,
        visible: false,
        getLabel: feature => feature.properties['TOPONYME'],
        surface: { color: 'rgb(0,40,120)', weight: 1 }
      }),
      new ContoursLayer('AOC Côtes de Moselle', {
        icon: '/assets/icons/map.svg',
        geojsonUrl: window.map_env.geojson['c41.geojson'],
        isolable: window.map_env.editor,
        interactive: true,
        visible: false,
        surface: { color: 'rgb(0,40,120)', weight: 1 }
      })
    ]
  }),

  new TilesLayer('Socle', {
    visible: false,
    icon: '/assets/icons/bedrock.svg',
    tiles: tilesURL('/assets/layers/socle'),
    mixBlendMode: 'multiply',
    legend: [
      { label: 'Courbes de niveau', filename: 'C05', shape: 'line', stroke: 'rgba(0,0,0,0.4)', strokeWidth: 2 }
    ]
  }),

  new TilesLayer('IGN', {
    visible: true,
    icon: '/assets/icons/map.svg',
    tiles: tilesURL('/assets/layers/ign'),
    opacity: 0.3
  }),

  new Layer('Formations géologiques', {
    icon: '/assets/icons/bedrock.svg',
    visible: false,
    children: [
      new TilesLayer('Aplats', {
        visible: true,
        icon: '/assets/icons/bedrock.svg',
        tiles: tilesURL('/assets/layers/formations-geologiques/fill'),
        legend: [
          { label: 'alluvions', filename: 'C00', fill: 'rgb(254,235,188)' },
          { label: 'argile', filename: 'C00', fill: 'rgb(169,202,254)' },
          { label: 'argile calcaire', filename: 'C00', fill: 'rgb(174,202,254)' },
          { label: 'blocs', filename: 'C00', fill: 'rgb(254,253,176)' },
          { label: 'calcaire', filename: 'C00', fill: 'rgb(189,116,87)' },
          { label: 'calcaire argileux (80%<CO3<90%)', filename: 'C00', fill: 'rgb(158,97,72)' },
          { label: 'calcaire bioclastique', filename: 'C00', fill: 'rgb(197,150,73)' },
          { label: 'calcaire récifal', filename: 'C00', fill: 'rgb(189,142,55)' },
          { label: 'calcaire sableux', filename: 'C00', fill: 'rgb(189,142,55)' },
          { label: 'conglomérat', filename: 'C00', fill: 'rgb(254,214,86)' },
          { label: 'conglomérat polygnique', filename: 'C00', fill: 'rgb(254,203,82)' },
          { label: 'craie', filename: 'C00', fill: 'rgb(254,192,79)' },
          { label: 'déchets d\'industries extractives', filename: 'C00', fill: 'rgb(253,181,75)' },
          { label: 'dolomie', filename: 'C00', fill: 'rgb(253,170,71)' },
          { label: 'dolomie grseuse', filename: 'C00', fill: 'rgb(253,156,60)' },
          { label: 'fer', filename: 'C00', fill: 'rgb(253,37,13)' },
          { label: 'graviers', filename: 'C00', fill: 'rgb(254,121,19)' },
          { label: 'grès', filename: 'C00', fill: 'rgb(237,102,10)' },
          { label: 'grès argileux', filename: 'C00', fill: 'rgb(194,126,25)' },
          { label: 'grès grossier', filename: 'C00', fill: 'rgb(152,57,20)' },
          { label: 'grès micac', filename: 'C00', fill: 'rgb(144,45,25)' },
          { label: 'limon', filename: 'C00', fill: 'rgb(237,234,199)' },
          { label: 'marne (33%<CO3<66%)', filename: 'C00', fill: 'rgb(222,169,83)' },
          { label: 'marne calcaire (66<CO3%<80)', filename: 'C00', fill: 'rgb(235,179,66)' },
          { label: 'marne sableuse', filename: 'C00', fill: 'rgb(139,165,191)' },
          { label: 'oolite ferrugineuse', filename: 'C00', fill: 'rgb(227,26,28)' },
          { label: 'quartzite', filename: 'C00', fill: 'rgb(208,22,35)' },
          { label: 'sable', filename: 'C00', fill: 'rgb(233,235,109)' },
          { label: 'tourbe', filename: 'C00', fill: 'rgb(195,7,37)' }
        ].filter(({ label }) => [
          // These labels are the one found in the geojson, so filtering
          // the whole legend to only show the revelant entries
          'alluvions',
          'argile calcaire',
          'argile',
          'blocs',
          'calcaire argileux (80%<CO3<90%)',
          'calcaire bioclastique',
          'calcaire récifal',
          'calcaire sableux',
          'calcaire',
          'conglomérat polygénique',
          'déchets d\'industries extractives',
          'limon',
          'marne (33%<CO3<66%)',
          'marne calcaire (66<CO3%<80)',
          'marne sableuse',
          'oolite ferrugineuse'
        ].includes(label))
      }),
      new TilesLayer('Motifs', {
        visible: true,
        icon: '/assets/icons/bedrock.svg',
        tiles: tilesURL('/assets/layers/formations-geologiques/pattern')
      })
    ]
  }),

  new TilesLayer('Hypsométrie', {
    visible: false,
    icon: '/assets/icons/bedrock.svg',
    tiles: tilesURL('/assets/layers/hypsometrie')
  }),

  new TilesLayer('Mobilités douces', {
    visible: false,
    icon: '/assets/icons/signs.svg',
    tiles: tilesURL('/assets/layers/mobilite-douce'),
    legend: [
      { label: 'Routes, rues et ruelles', filename: 'C17', shape: 'line', stroke: 'white', fill: '#DDD', strokeWidth: 2 },
      { label: 'Pistes cyclables', filename: 'C37', shape: 'line', stroke: '#ff00ff', dash: [3, 3], strokeWidth: 2 },
      { label: 'Véloroute', filename: 'C40', shape: 'line', stroke: '#ff00ff', strokeWidth: 2 },
      { label: 'Chemins piétons', filename: 'C19', shape: 'line', stroke: 'white', fill: '#DDD', dash: [3, 3], strokeWidth: 2 },
      { label: 'Balades natures', filename: 'C47', shape: 'line', stroke: '#ff3c3c', dash: [3, 3], strokeWidth: 2 },
      { label: 'GR5', filename: 'C20', shape: 'line', stroke: '#ff3c3c', strokeWidth: 2 },
      { label: 'Route des vins', filename: 'C39', shape: 'line', stroke: '#7850dc', strokeWidth: 2 }
    ]
  }),

  new TilesLayer('Voies et infrastructures', {
    visible: false,
    icon: '/assets/icons/car.svg',
    tiles: tilesURL('/assets/layers/voies-et-infrastructures'),
    legend: [
      { label: 'Voies ferrées', filename: 'C36', shape: 'custom', svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><line stroke="#505A64" x1="3" y1="2" x2="3" y2="8"/><line stroke="#505A64" x1="7" y1="2" x2="7" y2="8"/><line stroke="#505A64" x1="0" y1="5" x2="10" y2="5"/>' },
      { label: 'Aires de triage, parkings et port', filename: 'C42', shape: 'custom', svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16"><line x1="0" y1="16" x2="0" y2="0" stroke="#ff7800"/><line x1="4" y1="16" x2="4" y2="0" stroke="#ff7800"/><line x1="8" y1="16" x2="8" y2="0" stroke="#ff7800"/><line x1="12" y1="16" x2="12" y2="0" stroke="#ff7800"/><line x1="16" y1="16" x2="16" y2="0" stroke="#ff7800"/></svg>` },
      { label: 'Piste aérodrome', filename: 'C50', fill: '#ff7800' },
      { label: 'Lignes électriques', filename: 'C43', shape: 'line', stroke: '#ffff00' },
      { label: 'Autoroutes, nationales et départementales', filename: 'C16', shape: 'line', stroke: '#ff7800', strokeWidth: 4 }
    ],
    children: [
      new PointsLayer('Pylônes', {
        visible: false,
        interactive: false,
        zoomRange: [5, 7],
        icon: '/assets/icons/pylon.svg',
        geojsonUrl: window.map_env.geojson['c44.geojson'],
        symbol: '/assets/icons/markers/pylon.svg',
        symbolSize: [20, 20]
      })
    ]
  }),

  new TilesLayer('Implantation urbaine', {
    visible: false,
    icon: '/assets/icons/building.svg',
    tiles: tilesURL('/assets/layers/implantation-urbaine'),
    legend: [
      { label: 'Parcellaire', filename: 'C03', zoomRange: [6, 6], shape: 'line', stroke: 'black' },
      { label: 'Bâti', filename: 'C08', fill: 'black' },
      { label: 'Bâti industriel', filename: 'C09', fill: 'rgb(255,180,220)' },
      { label: 'Cimetières', filename: 'C34', fill: 'rgb(0,40,120)' },
      { label: 'Terrains de sport', filename: 'C35', fill: '#ff7878' },
      { label: 'Zones commerciales et industrielles', filename: 'C49', shape: 'custom', svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16"><line x1="0" y1="16" x2="0" y2="0" stroke="rgb(255,180,220)"/><line x1="4" y1="16" x2="4" y2="0" stroke="rgb(255,180,220)"/><line x1="8" y1="16" x2="8" y2="0" stroke="rgb(255,180,220)"/><line x1="12" y1="16" x2="12" y2="0" stroke="rgb(255,180,220)"/><line x1="16" y1="16" x2="16" y2="0" stroke="rgb(255,180,220)"/></svg>` }
    ],
    children: [
      new MarkersLayer('Lavoirs', {
        icon: '/assets/icons/laundry.svg',
        zoomRange: [1, 7],
        markers: window.map_env.markers['Lavoirs']
      })
    ]
  }),

  new Layer('Marqueurs patrimoniaux et naturels', {
    visible: false,
    icon: '/assets/icons/marker.svg',
    children: [
      new MarkersLayer('Bâtiments patrimoniaux', {
        icon: '/assets/icons/church.svg',
        visible: true,
        zoomRange: [1, 7],
        markers: window.map_env.markers['Bâtiments patrimoniaux']
      }),

      new MarkersLayer('Belvédères', {
        icon: '/assets/icons/panorama.svg',
        visible: true,
        zoomRange: [1, 7],
        markers: window.map_env.markers['Belvédères']
      }),

      new MarkersLayer('Repères', {
        icon: '/assets/icons/marker.svg',
        visible: true,
        zoomRange: [1, 7],
        markers: window.map_env.markers['Repères']
      }),

      new MarkersLayer('Forts', {
        icon: '/assets/icons/castle.svg',
        visible: true,
        zoomRange: [1, 7],
        markers: window.map_env.markers['Forts']
      })
    ]
  })
]

export function get (title) {
  if (!title) return
  const layer = layers.find(layer => layer.title.toUpperCase() === title.toUpperCase())
  if (!layer && !window.ENV.production) console.warn('Layer not found:', title)
  return layer
}

export const use = (titles = []) => titles.map(title => get(title)).filter(Boolean)

export default {
  get,
  use,
  get layers () { return layers }
}
