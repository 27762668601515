export default el => {
  if (!el) return

  const parents = []
  while (el) {
    parents.unshift(el)
    el = el.parentNode
  }
  return parents
}
