import LAYERS from '@layers'
import { createStore } from '@internet/state'

const store = createStore({
  // Related to the <MapPlayer> creation
  tileSize: 256,
  zoomRange: [1, 6],
  bbox: [917147.156155741, 6885201.29637512, 937157.321376621, 6903953.67978212],

  // Related to the <MapPlayer> use
  hash: undefined,
  zoom: undefined,
  fullscreen: false,
  center: undefined,
  isolatedFeature: undefined,

  // Store.layers are used during <MapPlayer> creation, <MapPlayer> use
  // and when restoring a previous state (see controllers/map-editor)
  // The order defined the z-order of the layers on the map player
  // SEE: controllers/map for the instanciation of the layers
  layers: LAYERS.use([
    'hypsométrie',
    'espaces de nature',
    'espaces agricoles',
    'formations géologiques',
    'hydrographie',
    'socle',
    'ign',
    'mobilités douces',
    'voies et infrastructures',
    'implantation urbaine',
    'marqueurs patrimoniaux et naturels',
    'communes du plan paysage côtes de moselle',
    'statuts de gestion, protection et de mise en valeur',
    'les treize secteurs',

    // Update from 2021-11
    'territoire attractif',
    'paysages connus lisibles et vivants',
    'transition énergétique',
    'transition écologique',

    'nuit',
    !window.ENV.production && 'debug'
  ]),

  // This key is specifically used to determine a different layers order than
  // the default one used in store.layers (aka z-index), to use in the layers
  // list
  // SEE: controllers/map for the mounting of the layers to the DOM
  layersEntries: [
    !window.ENV.production && 'debug',
    'nuit',
    'communes du plan paysage côtes de moselle',
    'hydrographie',
    'implantation urbaine',
    'voies et infrastructures',
    'mobilités douces',
    'marqueurs patrimoniaux et naturels',
    'espaces agricoles',
    'espaces de nature',
    'statuts de gestion, protection et de mise en valeur',
    'les treize secteurs',

    'territoire attractif',
    'paysages connus lisibles et vivants',
    'transition énergétique',
    'transition écologique',

    'socle',
    'ign',
    'formations géologiques',
    'hypsométrie'
  ]
})

Object.values(store).forEach(signal => {
  signal.dispatch = () => signal.set(signal.get(), true)
})

if (!window.ENV.production) window._store = store
export default store

export const COPIABLE_KEYS = [
  'isolatedFeature',
  'zoom',
  'center'
]
