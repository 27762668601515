/* global fetch */

export default async url => {
  try {
    const response = await fetch(url)
    const data = await response.json()
    return data.features
  } catch (error) {
    console.error(error)
  }
}
