export default rect => {
  const width = Math.abs(rect[0] - rect[2])
  const height = Math.abs(rect[1] - rect[3])
  const ratio = width / height
  if (ratio === 1) return

  const pad = Math.abs(width - height) / 2

  if (ratio < 1) {
    rect[0] -= pad
    rect[2] += pad
  } else {
    rect[1] -= pad
    rect[3] += pad
  }

  return rect
}
