/* global L */
import Store from '@store'

import hash from 'object-hash'

import MapLayer from 'components/MapLayer'
import noop from 'utils/noop'

export default class Layer {
  constructor (title, {
    icon = '',
    suffix = null,

    // This is used for retro-compatibility to enforce a pre-defined UID,
    // in case the title (from which the UID is hashed) has to change. Enforcing
    // a previously used UID will avoid breaking all pointers to that Layer
    // instance
    UID = null,

    zoomRange = [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY],
    disabled = false,
    visible = true,
    actionable = true,
    mixBlendMode = undefined,
    solo = false,

    tiles = '',
    markers = [],

    legend = [],
    children = [],
    hiddenFromDOM = false
  } = {}) {
    if (!title) throw new Error('Layer.title must be defined')
    this.title = title
    this.icon = icon
    this.suffix = suffix

    this.UID = UID || hash(title)

    this.zoomRange = zoomRange
    this.disabled = disabled
    this.visible = visible
    this.actionable = actionable
    this.mixBlendMode = mixBlendMode
    this.solo = solo

    this.legend = legend
    this.hiddenFromDOM = hiddenFromDOM
    this.children = children.map(child => {
      child.parent = this
      return child
    })

    this.didInit(arguments[1])
  }

  didInit () {}
  render (map) { return L.layerGroup() }
  didRender (map) {}

  toggle () { this.visible ? this.hide() : this.show() }
  show () {
    this.visible = true
    this.update()
  }

  hide () {
    this.visible = false
    this.update()
  }

  // There is more than just the internal Layer.hidden flag to determine
  // a Layer visiblity. This function computes this final visible state
  isHidden () {
    if (!this.visible) return true
    return this.isForcedHidden()
  }

  isForcedHidden () {
    if (this.disabled) return true
    if (this.parent && this.parent.isHidden()) return true
    if (this.map && (this.map.zoom < this.zoomRange[0] || this.map.zoom > this.zoomRange[1])) return true
    return false
  }

  update ({ silent = false } = {}) {
    this.children.forEach(child => child.update({ silent: true }))
    if (this.solo) this._updateSolo()
    if (this.element) this.element[this.disabled ? 'disable' : 'enable']()
    if (!silent) Store.layers.dispatch()
  }

  _updateSolo () {
    Store.layers.current.forEach(layer => {
      if (layer.UID === this.UID) return

      layer.disabled = this.visible
      layer.update()
    })
  }

  // Mount the Layer abstraction in the DOM, creating a MapLayer
  // component
  toDOM (parent) {
    if (this.hiddenFromDOM) return
    this.element = new MapLayer(this, { actionable: this.actionable })
    this.element.mount(parent)

    this.children.forEach(child => child.toDOM(this.element.refs.children))
  }

  // Add the Layer abstraction to a MapPlayer instance, not that the layer will
  // not be render until the first time Layer.getRender is called
  toMap (map, { update = true } = {}) {
    this.map = map
    this.map.addLayer(this, { update })
    this.children.forEach(child => child.toMap(this.map, { update }))
  }

  async getRender (callback = noop) {
    if (!this.rendered) {
      this.rendered = await this.render(this.map)
      this.didRender(this.map)

      if (this.mixBlendMode) {
        this.rendered.on('add', ({ target }) => {
          target._container.style['mix-blend-mode'] = this.mixBlendMode
        })
      }
    }

    callback(this.rendered)
    return this.rendered
  }
}
