import Store from '@store'
import html from 'nanohtml'

export default ({
  label = '',
  filename = undefined,
  shape = 'square',
  fill = 'transparent',
  stroke = 'transparent',
  svg = '',
  strokeWidth = 1,
  dash = false,
  zoomRange = [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY]
}) => {
  if (!window.ENV.production && filename) label += ` (${filename})`

  let svgElement
  switch (shape) {
    case 'custom':
      svgElement = html([svg])
      break
    case 'square':
      svgElement = html`<svg viewBox='0 0 100 100' width='100px' height='100px' xmlns='http://www.w3.org/2000/svg'>
        <rect
          width='100'
          height='100'
          fill='${fill}'
          stroke='${stroke}'
          stroke-width='${strokeWidth}'
          vector-effect='non-scaling-stroke'
        />
      </svg>`
      break
    case 'circle':
      svgElement = html`<svg viewBox='0 0 100 100' width='100px' height='100px' xmlns='http://www.w3.org/2000/svg'>
        <circle
          cx='50'
          cy='50'
          r='50'
          fill='${fill}'
          stroke='${stroke}'
          stroke-width='${strokeWidth}'
          vector-effect='non-scaling-stroke'
        />
      </svg>`
      break
    case 'line':
      svgElement = html`<svg viewBox='0 0 100 100' width='100px' height='100px' xmlns='http://www.w3.org/2000/svg'>
        <rect
          width='100'
          height='100'
          fill='${fill}'
          vector-effect='non-scaling-stroke'
        />
        <line
          x1='0'
          x2='100'
          y1='50'
          y2='50'
          stroke-dasharray='${dash ? dash.join(' ') : 0}'
          stroke='${stroke}'
          stroke-width='${strokeWidth}'
          vector-effect='non-scaling-stroke'
        />
      </svg>`
      break
    default:
      throw new Error(`map-layer-legend shape '${shape}' is unknown`)
  }

  const legend = html`<li class='map-layer-legend'>
    <div class='map-layer-legend__pattern'>${svgElement}</div>
    <div class='map-layer-legend__label'>${label}</div>
  </li>`

  Store.zoom.listen(z => legend.setAttribute('disabled', z < zoomRange[0] || z > zoomRange[1]))
  return legend
}
