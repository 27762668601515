/* global L */
import Layer from 'abstractions/Layer'

const SIZE = [30, 30]

export default class MarkersLayer extends Layer {
  didInit ({ markers } = {}) {
    if (!markers) throw new Error('MarkersLayer.markers must be defined')
    this.markers = markers
  }

  render (map) {
    return L.layerGroup(this.markers.map(marker => {
      const hasIcon = marker.icon

      const icon = hasIcon
        ? L.icon({
          iconUrl: marker.icon,
          iconSize: SIZE,
          iconAnchor: [SIZE[0] / 2, SIZE[1]],
          tooltipAnchor: [SIZE[0] / 2, -SIZE[1]]
        })
        : L.divIcon({
          className: 'leaflet-invisible-icon',
          tooltipAnchor: [0, 10]
        })

      const tooltip = hasIcon
        ? { direction: 'top' }
        : {
          direction: 'top',
          permanent: true,
          className: 'map__marker--text-only',
          offset: [0, 0]
        }

      const coordinates = map.uncorrectLatLng([marker.lat, marker.lng])
      const leafletMarker = L.marker(coordinates, { riseOnHover: hasIcon, icon })

      leafletMarker.bindTooltip(marker.value, tooltip)
      leafletMarker.on('click', () => map.LeafletMap.fitBounds(L.latLngBounds([coordinates])))

      return leafletMarker
    }))
  }
}
