/* global L */
import Store from '@store'
import hash from 'object-hash'
import Layer from 'abstractions/Layer'
import fetchGeoFeatures from 'utils/fetch-geojson-features'

export default class ContoursLayer extends Layer {
  didInit ({
    geojsonUrl = null,
    isolable = true,
    interactive = true,
    getLabel = function () { return this.title },
    label = {},
    surface = {},
    overlay = {}
  } = {}) {
    if (!geojsonUrl) throw new Error('ContoursLayer.geojsonUrl must be defined')
    this.geojsonUrl = geojsonUrl
    this.isolable = isolable
    this.interactive = interactive
    this.getLabel = getLabel
    this.labelStyle = label
    this.surfaceStyle = surface
    this.overlayStyle = overlay
  }

  async render (map) {
    this.features = await fetchGeoFeatures(this.geojsonUrl)
    if (!this.features || !this.features.length) return

    const layer = L.layerGroup()

    this.features.forEach(feature => {
      feature.hash = hash(feature.geometry)

      const coords = [
        map.polygon.map(map.uncorrectLatLng),
        feature.geometry.coordinates[0][0].map(map.uncorrectLatLng)
      ]

      feature.surface = L.polygon(coords[1], Object.assign({
        color: 'black',
        fillOpacity: 0.001
      }, this.surfaceStyle, {
        interactive: this.interactive || this.isolable
      }))

      feature.overlay = L.polygon(coords, Object.assign({
        color: 'white'
      }, this.overlayStyle, {
        fillOpacity: 0,
        stroke: false,
        interactive: false
      }))

      feature.surface.on('click', () => {
        if (!this.isolable) this.fit(feature, map)
        else Store.isolatedFeature.set(feature.isolated ? null : feature.hash)
      })

      feature.surface.addTo(layer)

      feature.label = this.getLabel(feature)
      if (feature.label) {
        feature.surface.bindTooltip(feature.label, Object.assign({
          permanent: false,
          direction: 'center',
          sticky: false
        }, this.labelStyle))
      }
    })

    // Make sure that all overlays will be applied on top of all features
    this.features.forEach(feature => feature.overlay.addTo(layer))

    return layer
  }

  didRender () {
    Store.isolatedFeature.listen(hash => this.resolveIsolation(hash))
    this.resolveIsolation(Store.isolatedFeature.current)
  }

  resolveIsolation (hash) {
    this.features.forEach(feature => {
      this[feature.hash === hash ? 'isolate' : 'unisolate'](feature)
    })
  }

  isolate (feature) {
    feature.isolated = true
    feature.overlay.setStyle({ fillOpacity: this.overlayStyle.fillOpacity || 0.75 })
  }

  unisolate (feature) {
    if (!feature.isolated) return
    feature.isolated = false
    feature.overlay.setStyle({ fillOpacity: 0 })
  }

  fit (feature, map) {
    map.LeafletMap.fitBounds(feature.surface.getBounds(), { animate: false })
  }
}
