// This is based on leaftlet's Control.Scale implementation
// SEE: https://github.com/Leaflet/Leaflet/blob/master/src/control/Control.Scale.js

import Store from '@store'
import html from 'nanohtml'

import DomComponent from 'abstractions/DomComponent'

const SCALE_WIDTH = 64

export default class MapScale extends DomComponent {
  didInit () {
    this.bindFuncs(['update'])

    const bbox = Store.bbox.current
    const maxMeters = Math.abs(bbox[0] - bbox[2])
    this.tileSizeInMeters = zoom => (maxMeters / (2 ** zoom))
  }

  render () {
    this.refs.bar = html`<div class='map-scale__bar'></div>`
    this.refs.value = html`<div class='map-scale__value'>1 : 10000</div>`
    return html`
      <div class='map-scale'>
        ${this.refs.bar}
        ${this.refs.value}
      </div>
    `
  }

  didMount () {
    Store.zoom.listen(this.update)
  }

  update (zoom) {
    const meters = this.tileSizeInMeters(zoom)
    const ratio = Store.tileSize.current / SCALE_WIDTH
    const roundedMeters = this.round(meters / ratio)
    const label = this.beautifyMeters(roundedMeters)

    const width = (SCALE_WIDTH / (roundedMeters / (meters / ratio)))
    this.refs.bar.style.setProperty('--scale-width', width + 'px')
    this.refs.bar.setAttribute('data-label', label)
    this.refs.value.innerText = this.beautifyScale(this.computeScale(roundedMeters))
  }

  // SEE: https://gis.stackexchange.com/a/29851
  computeScale (meters) {
    const dpi = 2.54 / 92
    return ((meters / Store.tileSize.current) / dpi) * 1000
  }

  beautifyScale (scale) {
    return `1 : ${Math.round(scale)}`.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')
  }

  beautifyMeters (value) {
    return value < 1000 ? value + ' m' : (value / 1000) + ' km'
  }

  round (num) {
    const pow10 = Math.pow(10, (Math.floor(num) + '').length - 1)
    let d = num / pow10

    d = d >= 10
      ? 10 : d >= 5
        ? 5 : d >= 3
          ? 3 : d >= 2
            ? 2 : 1
    return pow10 * d
  }
}
