import 'nodelist-foreach'
import 'flickity-fade'
import html from 'nanohtml'
import Flickity from 'flickity'

import DomComponent from 'abstractions/DomComponent'

export default class Slideshow extends DomComponent {
  didInit (figures, {
    draggable = false,
    wrapAround = false,
    autoPlay = false,
    fade = true,
    adaptiveHeight = true,
    cellAlign = 'center'
  } = {}) {
    this.refs.figures = figures
    this.flickityOptions = {
      draggable,
      wrapAround,
      autoPlay,
      fade,
      adaptiveHeight,
      cellAlign
    }

    this.bindFuncs(['add', 'has', 'select', 'resize'])
  }

  get selected () {
    return this.flickity && this.flickity.selectedElement
  }

  render () {
    this.refs.caption = html`<div class='slideshow__caption'>`
    return html`<div class='slideshow'>`
  }

  didMount () {
    this.flickity = new Flickity(this.refs.base, this.flickityOptions)
    this.refs.figures.forEach(this.add)
    this.refs.base.appendChild(this.refs.caption)

    this.flickity.on('select', () => {
      if (!this.selected) return

      // Caching the caption inside the figure object because why not
      if (!this.selected.caption) {
        const caption = this.selected.querySelector('figcaption')
        this.selected.caption = caption && caption.innerHTML
      }

      this.refs.caption.innerHTML = this.selected.caption
    })
  }

  add (figure) {
    figure.classList.add('in-flickity')
    figure.classList.add('in-slideshow')

    this.flickity.append(figure)
  }

  has (figure) {
    let found = false
    for (let f of this.refs.figures) {
      if (f !== figure) continue
      found = true
      break
    }
    return found
  }

  select (index) {
    if (!this.flickity) return
    this.flickity.selectCell(index)
  }

  resize () {
    if (!this.flickity) return
    this.flickity.resize()
  }

  willUnmount () {
    this.flickity.destroy()
  }
}
