/* global L */
import Layer from 'abstractions/Layer'
import fetchGeoFeatures from 'utils/fetch-geojson-features'

export default class PointsLayer extends Layer {
  didInit ({
    geojsonUrl = null,
    symbol = null,
    symbolSize = [40, 40],
    symbolColor = 'black', // Used if symbol === 'circle'
    label = {},
    getLabel = function () { return this.title },
    getColor = undefined, // Will override symbolColor if defined
    interactive = true
  } = {}) {
    if (!geojsonUrl) throw new Error('ContoursLayer.geojsonUrl must be defined')
    this.geojsonUrl = geojsonUrl
    this.symbol = symbol
    this.symbolSize = symbolSize
    this.symbolColor = symbolColor
    this.interactive = interactive
    this.getLabel = getLabel
    this.getColor = getColor
    this.labelStyle = label
  }

  async render (map) {
    const points = await fetchGeoFeatures(this.geojsonUrl)
    if (!points || !points.length) return

    return L.layerGroup(points.map(feature => {
      const [lat, lon] = feature.geometry.type === 'Point'
        ? feature.geometry.coordinates
        : feature.geometry.coordinates[0]

      let symbol = this.symbol
      const coordinates = map.uncorrectLatLng([lat, lon])
      const iconAnchor = [this.symbolSize[0] / 2, this.symbolSize[1]]

      const symbolColor = this.getColor
        ? this.getColor(feature)
        : this.symbolColor

      if (this.symbol === 'circle') {
        const svg = `
          <svg viewBox='0 0 100 100' width='100px' height='100px' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='50' cy='50' r='50' fill='${symbolColor}' />
          </svg>
        `
        symbol = 'data:image/svg+xml;base64,' + window.btoa(svg)
        iconAnchor[1] = this.symbolSize[1] / 2
      }

      if (this.symbol.startsWith('char:')) {
        const char = this.symbol.replace('char:', '')
        const svg = `
          <svg viewBox='-2 -2 104 104' width='100px' height='100px' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='50' cy='50' r='50' fill='white' stroke='${symbolColor}' stroke-width='1' vector-effect='non-scaling-stroke' />
            <text x='50' y='70' text-anchor='middle' font-family='monospace' font-size='80' fill='${symbolColor}'>${char}</text>
          </svg>
        `

        symbol = 'data:image/svg+xml;base64,' + window.btoa(svg)
      }

      const leafletMarker = L.marker(coordinates, {
        riseOnHover: this.interactive,
        interactive: this.interactive,
        icon: L.icon({
          iconUrl: symbol,
          iconSize: this.symbolSize,
          iconAnchor
        })
      })

      if (this.interactive) {
        leafletMarker.on('click', () => map.LeafletMap.fitBounds(L.latLngBounds([coordinates])))

        feature.label = this.getLabel(feature)
        if (feature.label) {
          leafletMarker.bindTooltip(feature.label, Object.assign({
            permanent: false,
            direction: 'top',
            offset: [0, -this.symbolSize[1]],
            sticky: false
          }, this.labelStyle))
        }
      }

      return leafletMarker
    }))
  }
}
