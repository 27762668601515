/* global L */

import Store from '@store'

import debounce from 'debounce'
import { map } from 'missing-math'

import lastOf from 'utils/array-last'
import makeSquare from 'utils/aabb-make-square'
import noop from 'utils/noop'

L.RasterCoords = require('leaflet-rastercoords')

export default class MapPlayer {
  constructor (container, {
    bbox,
    zoomRange = [0, 8],

    onload = noop,
    onmove = noop,
    onzoom = noop
  } = {}) {
    this.layers = []
    this.bbox = makeSquare(bbox)
    this.srcDimensions = [Store.tileSize.current * (2 ** zoomRange[1]), Store.tileSize.current * (2 ** zoomRange[1])]
    this.LeafletMap = L.map(container, {
      minZoom: zoomRange[0],
      maxZoom: zoomRange[1],
      maxBoundsViscosity: 0.0,
      attributionControl: false,
      doubleClickZoom: true,
      scrollWheelZoom: true,
      zoomAnimation: false,
      tap: false,
      fadeAnimation: true,
      touchZoom: false,
      zoomControl: false
    })
    this.RCS = new L.RasterCoords(this.LeafletMap, this.srcDimensions)
    this.tileLayerOptions = {
      tileSize: Store.tileSize.current,
      noWrap: true,
      bounds: [
        this.RCS.unproject([0, 0]),
        this.RCS.unproject(this.srcDimensions)
      ]
    }

    this.LeafletMap.addControl(L.control.zoom({ position: 'topleft' }))
    this.LeafletMap.setMaxBounds(this.tileLayerOptions.bounds)

    this.updateLayers = debounce(this.updateLayers.bind(this), 100)
    this.setCenter = debounce(this.setCenter.bind(this), 100)
    this.setZoom = debounce(this.setZoom.bind(this), 100)

    this.LeafletMap.on('load', onload.bind(this))
    this.LeafletMap.on('moveend', () => {
      const center = this.center
      center.fromMoveEvent = true
      onmove(center)
    })
    this.LeafletMap.on('zoomend', onzoom.bind(this))

    ;['correctLatLng', 'uncorrectLatLng', 'toScreen', 'toWorld', 'reset'].forEach(func => {
      this[func] = this[func].bind(this)
    })
  }

  get zoom () { return this.LeafletMap.getZoom() }
  setZoom (zoom) {
    this.LeafletMap.setZoom(zoom)
  }

  get center () { return this.LeafletMap.getCenter() }
  setCenter (position) {
    if (!position) return
    this.LeafletMap.panTo(position)
  }

  get container () { return this.LeafletMap._container }
  get polygon () {
    // This is useful to create holed polygon and overlays of the entire map
    return [
      [this.bbox[0], this.bbox[3]],
      [this.bbox[2], this.bbox[3]],
      [this.bbox[2], this.bbox[1]],
      [this.bbox[0], this.bbox[1]]
    ]
  }

  addLayer (layer, { update = true } = {}) {
    if (!layer) throw new Error('MapPlayer.addLayer: layer is undefined or null')
    layer.__zindex = this.layers.length
      ? lastOf(this.layers).__zindex + 1
      : 0
    this.layers.push(layer)
    if (update) this.updateLayers()
  }

  updateLayers () {
    this.layers.forEach(layer => {
      if (layer.isHidden()) {
        if (!layer.rendered) return
        this.LeafletMap.removeLayer(layer.rendered)
      } else {
        layer.getRender(() => {
          this.LeafletMap.addLayer(layer.rendered)
          layer.rendered.setZIndex(layer.__zindex)
        })
      }
    })
  }

  reset ({ resetZoom = true } = {}) {
    this.LeafletMap.invalidateSize()
    resetZoom && this.LeafletMap.fitWorld().zoomIn()
  }

  correctLatLng ([lat, lng]) {
    const { x, y } = this.RCS.project([lat, lng])
    return this.toWorld([x, y])
  }

  uncorrectLatLng ([lat, lng]) {
    const [x, y] = this.toScreen([lat, lng])
    return this.RCS.unproject([x, y])
  }

  toWorld ([x, y]) {
    return [
      map(x, 0, this.srcDimensions[0], this.bbox[0], this.bbox[2]),
      map(y, 0, this.srcDimensions[1], this.bbox[1], this.bbox[3])
    ]
  }

  toScreen ([lat, lng]) {
    return [
      map(lat, this.bbox[0], this.bbox[2], 0, this.srcDimensions[0]),
      map(lng, this.bbox[3], this.bbox[1], 0, this.srcDimensions[1])
    ]
  }
}
