/* global IntersectionObserver */
import 'nodelist-foreach'
import 'intersection-observer'

import Store from '@store'

const mapAnchorsObserver = new IntersectionObserver(handleMapAnchorsIntersection, {
  rootMargin: '0px 9999px' // Disable horizontal intersection
})

function handleMapAnchorsIntersection (entries) {
  entries.forEach(entry => {
    if (!entry.isIntersecting) return

    const hash = entry.target.getAttribute('data-hash')
    if (!hash) return
    Store.hash.set(hash)
  })
}

export default function (element) {
  mapAnchorsObserver.observe(element)

  return {
    element,
    destroy: function () {
      mapAnchorsObserver.unobserve(element)
    }
  }
}
