/* global L */
import Layer from 'abstractions/Layer'

export default class TilesLayer extends Layer {
  didInit ({ tiles, opacity } = {}) {
    if (!tiles) throw new Error('TilesLayer.tiles must be defined')
    this.tiles = tiles
    this.opacity = opacity
  }

  render (map) {
    return L.tileLayer(this.tiles, Object.assign(
      {},
      map.tileLayerOptions,
      { opacity: this.opacity }
    ))
  }
}
