import Store, { COPIABLE_KEYS } from '@store'
import JSONUrl from 'json-url/dist/browser/json-url-single'
import ClipboardJS from 'clipboard'
const codec = JSONUrl('lzw')

const btn = document.getElementById('jsMapEditorCopyCode')
if (btn) {
  const clipboard = new ClipboardJS(btn)
  clipboard.on('success', e => btn.classList.add('has-copied'))
}

async function update () {
  Store.hash.set(undefined)

  if (!btn) return
  const o = {}
  COPIABLE_KEYS.forEach(key => { o[key] = Store[key].current })
  o.layers = []
  Store.layers.current.map(layer => {
    if (!layer.visible) return
    o.layers.push(layer.UID)
    layer.children.forEach(child => {
      if (child.visible) o.layers.push(child.UID)
    })
  })

  const hash = await codec.compress(o)
  btn.classList.remove('has-copied')
  btn.setAttribute('data-clipboard-text', hash)
}

export async function restore (hash) {
  if (!hash) return

  const preset = await codec.decompress(hash)
  if (typeof preset !== 'object' || preset === null) return

  if (!window.ENV.production) console.log('Restoring hash', preset)

  if (preset.layers) {
    Store.layers.current.forEach(layer => {
      layer.visible = preset.layers.includes(layer.UID)
      layer.children.forEach(child => {
        // Child should be always visible when parent layer is invisible, so
        // that clicking the parent show all children by default
        // If the parent is visible, child visibility should be determined by
        // the hash
        child.visible = !layer.visible || preset.layers.includes(child.UID)
      })
      layer.update()
    })
  }

  if (preset.center) {
    delete preset.center.fromMoveEvent
  }

  COPIABLE_KEYS.forEach(key => preset[key] && Store[key].set(preset[key]))
}

Store.hash.listen(restore)

;[...COPIABLE_KEYS, 'layers'].forEach(key => Store[key].listen(update))
if (window.location.hash) Store.hash.set(window.location.hash)
