import Store from '@store'
import cuid from 'cuid'
import html from 'nanohtml'

import DomComponent from 'abstractions/DomComponent'
import LayerLegend from 'components/MapLayerLegend'

export default class LayerEntry extends DomComponent {
  didInit (layer, { actionable = true } = {}) {
    this.layer = layer
    this.actionable = actionable
    this.bindFuncs(['update'])
  }

  render () {
    // Children are populated in Layer.toDOM
    this.refs.children = html`<ul class="map-layer__children"></ul>`
    this.refs.checkbox = html`<input
      id="${cuid()}"
      type="checkbox"
      onchange=${() => this.refs.checkbox.checked ? this.layer.show() : this.layer.hide()}
    >`

    this.refs.legend = this.layer.legend.map(LayerLegend)

    if (!this.layer.icon && (this.layer.symbol === 'circle')) {
      const svg = `
        <svg viewBox='0 0 100 100' width='100px' height='100px' xmlns='http://www.w3.org/2000/svg'>
          <circle cx='50' cy='50' r='50' fill='${this.layer.symbolColor}' />
        </svg>
      `
      this.layer.icon = 'data:image/svg+xml;base64,' + window.btoa(svg)
    }

    return html`
      <li class="map-layer">
        <div class="map-layer__label">
          ${this.refs.checkbox}
          <label for="${this.refs.checkbox.id}" title="${this.layer.title}">
            <img src=${this.layer.icon} />
            <div class="map-layer__title" data-suffix=${this.layer.suffix}>${this.layer.title}</div>
          </label>
        </div>
        ${this.refs.children}
        <ul class="map-layer__legend">${this.refs.legend}</ul>
      </li>
    `
  }

  didMount () {
    this.update()
    Store.layers.listen(this.update)
    Store.zoom.listen(this.update)
  }

  update () {
    this.refs.checkbox.checked = !this.layer.isHidden()
    this.refs.checkbox.disabled = this.disabled || this.layer.isForcedHidden()

    this.refs.base.setAttribute('actionable', this.actionable)
    this.refs.base.setAttribute('visible', this.refs.checkbox.checked)
    this.refs.base.setAttribute('disabled', this.refs.checkbox.disabled)
  }
}
