/* global L */
import html from 'nanohtml'
import Layer from 'abstractions/Layer'

export default class TilesLayer extends Layer {
  didInit () {
    this.Constructor = L.GridLayer.extend({
      createTile: ({ x, y, z }) => html`<div class='leaflet-tile--debug' data-x='${x}' data-y='${y}' data-z='${z}' />`
    })
  }

  render (map) {
    return new this.Constructor(map.tileLayerOptions)
  }
}
