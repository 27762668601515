import Store from '@store'
import barba from '@barba/core'

import Article from 'components/Article'
import Menu from 'components/Menu'
import Map from 'components/Map'
import 'controllers/map-hash'

let article
const menu = new Menu()
menu.hydrate(document.querySelector('.menu'))

const map = new Map()
map.hydrate(document.querySelector('.map'))

// Handle map fullscreen toggling
Store.fullscreen.listen(fullscreen => {
  document.body.classList[fullscreen ? 'add' : 'remove']('has-fullscreen-map')
})

// Handle night mode toggling
Store.layers.listen(layers => {
  const isNight = layers.find(layer => layer.title === 'Nuit' && !layer.isHidden())
  document.body.setAttribute('data-night', !!isNight)
})

// Barba navigation
document.querySelector('[data-barba=container]') && barba.init({
  debug: !window.ENV.production,
  views: [{
    namespace: 'article',
    beforeEnter: () => {
      window.scrollTo(0, 0)
      Store.fullscreen.set(false)
      menu.update()
    },
    afterEnter: ({ next }) => {
      if (article) article.destroy({ removeBase: false })

      article = new Article()
      article.hydrate(next.container)
      map.bindAnchors(next.container)
    },
    beforeLeave: () => {
      article.destroy({ removeBase: false })
      article = undefined
      Store.hash.set(undefined)
    }
  }],

  requestError: (trigger, action, url, response) => barba.force(url),
  prevent: ({ el }) => el.classList && el.classList.contains('no-barba')
})
