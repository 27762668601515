import 'nodelist-foreach'

import DomComponent from 'abstractions/DomComponent'
import parentsOf from 'utils/dom-parents'

export default class Menu extends DomComponent {
  didMount () {
    this.refs.entries = this.refs.base.querySelectorAll('.menu-entry')
  }

  get activeEntry () {
    if (!this.mounted) return
    const href = window.location.href.replace(window.location.hash, '')

    let active
    for (let entry of this.refs.entries) {
      const link = entry.querySelector('.menu-entry__link')
      if (link && link.href === href) {
        active = entry
        break
      }
    }

    return active
  }

  update () {
    this.clear()

    if (!this.activeEntry) return

    this.activeEntry.classList && this.activeEntry.classList.add('is-active')
    parentsOf(this.activeEntry).forEach(parent => {
      if (!parent || !parent.classList) return
      if (!parent.classList.contains('menu-entry')) return
      parent.classList.add('is-open')
    })
  }

  clear () {
    this.refs.entries.forEach(entry => {
      entry.classList.remove('is-active')
      entry.classList.remove('is-open')
    })
  }
}
